import React from 'react';
import QRCode from 'qrcode.react';
import logo from './static/icon.png';
import iOS from './static/ios.png';

const QR_CODE_VALUE = 'https://www.moli688.top';
const QR_CODE_COLOR = '#000000';

const Home = () => {
    return (
        <div style={styles.app}>
            <header style={styles.header}>
                {/* 您可以在此处添加任何导航栏内容 */}
            </header>
            <main style={styles.main}>
                <img src={logo} alt="Logo" style={styles.logo}/>
                <h1 style={styles.appName}>易排班</h1>
                <p style={styles.tagline}>轻 / 松 / 管 / 理 / 排 / 班</p>
                <p style={styles.tagline}>一键设置倒班</p>
                <div style={styles.qrContainer}>
                    <QRCode
                        value={QR_CODE_VALUE}
                        fgColor={QR_CODE_COLOR}
                        size={150}
                        imageSettings={{
                            src: logo,
                            excavate: true,
                            height: 32,
                            width: 32,
                        }}
                    />
                </div>
            </main>
            <footer style={styles.footer}>
                <div style={styles.downloadLinks}>
                    <a className='download' href='https://apps.apple.com/cn/app/id1547232838'
                       style={styles.downloadLink}>
                        <img src={iOS} alt="iOS" style={styles.channelImg}/>
                        <p style={styles.linkText}>iOS</p>
                    </a>
                    {/*<a className='download' href='https://brush-set.oss-cn-beijing.aliyuncs.com/shift-calendar.apk'*/}
                    {/*   style={styles.downloadLink}>*/}
                    {/*    <img src={And} alt="Android" style={styles.channelImg}/>*/}
                    {/*    <p style={styles.linkText}>Android Download</p>*/}
                    {/*</a>*/}
                    {/*<a className='download' href='https://play.google.com/store/apps/details?id=com.shiftcalendar'*/}
                    {/*   style={styles.downloadLink}>*/}
                    {/*    <img className="channel-img"*/}
                    {/*         src='https://www.gstatic.com/android/market_images/web/play_prism_hlock_2x.png'*/}
                    {/*         alt="Google Play" style={styles.channelImg}/>*/}
                    {/*    <p style={styles.linkText}>Android Google Play</p>*/}
                    {/*</a>*/}
                </div>
                <div style={styles.links}>
                    <a href='/privacy' style={styles.link}>隐私政策</a>
                    <a href='/terms' style={styles.link}>服务条款</a>
                    <a href='mailto:suggestpaqe@163.com' style={styles.link}>联系我们</a>
                </div>
            </footer>
        </div>
    );
};

const styles = {
    app: {
        fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
        textAlign: 'center',
        color: '#333',
        backgroundColor: '#f5f5f5',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px',
    },
    header: {
        width: '100%',
        padding: '10px',
        borderBottom: '2px solid #ddd',
    },
    main: {
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        width: '120px',
        marginBottom: '20px',
    },
    appName: {
        fontSize: '32px',
        margin: '10px 0',
        color: '#0056b3',
    },
    tagline: {
        fontSize: '18px',
        color: '#777',
        margin: '5px 0',
    },
    qrContainer: {
        margin: '20px 0',
    },
    footer: {
        width: '100%',
        padding: '20px',
        backgroundColor: '#fff',
        borderTop: '2px solid #ddd',
    },
    footerText: {
        fontSize: '16px',
        marginBottom: '10px',
    },
    downloadLinks: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', // 确保图标垂直对齐
        marginBottom: '20px',
    },
    downloadLink: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 10px',
        textDecoration: 'none',
        color: '#333',
    },
    channelImg: {
        width: '50px',
        height: '50px', // 强制统一高度
        marginBottom: '5px',
        objectFit: 'contain', // 确保图片内容适应
    },
    linkText: {
        fontSize: '14px',
    },
    links: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
    },
    link: {
        margin: '0 10px',
        textDecoration: 'none',
        color: '#0056b3',
        fontSize: '14px',
    },
};

export default Home;