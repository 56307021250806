import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./Home";
import Privacy from "./Privacy";
import Term from "./Term";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={Home()}/>
                <Route path="/privacy" element={Privacy()}/>
                <Route path="/terms" element={Term()}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
