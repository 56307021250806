import React from 'react';
import './Term.css';

// Sample Terms of Service
const termsOfService = {
    en: (
        <div>
            <h1>Terms of Service</h1>
            <p>Last Updated: August 14, 2024</p>
            <p>Welcome to EasySchedule (referred to as "we," "our," or "this App"). Please read these Terms of
                Service carefully before using this App. By using this App, you agree to and accept these terms.</p>

            <h2>1. License to Use</h2>
            <p>This App grants you a limited, non-exclusive, non-transferable license to use the App for personal,
                non-commercial purposes. You may not copy, modify, distribute, sell, or lease any part of this App
                without our explicit written consent.</p>

            <h2>2. User Responsibilities</h2>
            <p>You agree to comply with all applicable laws and regulations when using this App. You must not post,
                transmit, distribute, or store any unlawful, harmful, abusive, or otherwise inappropriate content
                through this App.</p>

            <h2>3. Intellectual Property</h2>
            <p>The App and all its content (including but not limited to text, images, logos, software, etc.) are owned
                by us or our licensors. You may not copy, imitate, or use any part of this App without our prior written
                consent.</p>

            <h2>4. iOS In-App Purchases and Subscriptions</h2>
            <h3>4.1 In-App Purchases</h3>
            <p>This App offers in-app purchases through Apple’s App Store. These purchases may include, but are not
                limited to, one-time purchases, consumable purchases, non-consumable purchases, and auto-renewable
                subscriptions. After you make a purchase, the payment will be charged to your Apple ID account. Please
                note that all transactions are processed by Apple, and we do not handle payments directly.</p>

            <h3>4.2 Subscriptions</h3>
            <p>This App offers auto-renewable subscription options. Subscriptions will automatically renew unless you
                cancel at least 24 hours before the end of the current subscription period. The renewal payment will be
                charged to your Apple ID account within 24 hours before the current period ends, and a new subscription
                period will begin. You can manage or cancel your subscriptions at any time through your Apple ID account
                settings.</p>

            <h3>4.3 Refund Policy</h3>
            <p>All in-app purchases and subscription fees are non-refundable. For auto-renewable subscriptions, you may
                cancel renewal at any time before the current subscription period ends, but the fees already paid will
                not be refunded. If you believe a charge was made in error or have any questions about payment, please
                contact Apple Support directly.</p>

            <h2>5. Termination</h2>
            <p>We reserve the right to terminate your access to this App immediately without notice if you breach these
                Terms of Service.</p>

            <h2>6. Disclaimer</h2>
            <p>This App is provided "as is," without any express or implied warranties. We do not guarantee that the
                App's functions will be uninterrupted or error-free, nor do we take responsibility for any loss or
                damage arising from your use of the App.</p>

            <h2>7. Limitation of Liability</h2>
            <p>To the maximum extent permitted by applicable law, we shall not be liable for any indirect, incidental,
                special, or consequential damages arising out of or in connection with your use or inability to use this
                App.</p>

            <h2>8. Modifications to Terms</h2>
            <p>We reserve the right to modify these Terms of Service at any time. Any changes will become effective when
                we post the updated Terms of Service. We encourage users to review these terms periodically to stay
                informed of any changes.</p>

            <h2>9. Contact Us</h2>
            <p>If you have any questions or suggestions regarding these Terms of Service, please contact us at
                suggestpaqe@163.com.</p>
        </div>),
    zh: (
        <div>
            <h1>用户条款 </h1>
            <p>更新日期：2024年8月14日</p>
            <p>欢迎使用易排班（以下简称“我们”或“本应用”）。请在使用本应用前仔细阅读以下用户条款。使用本应用即表示您同意并接受这些条款。</p>
            <h2>1. 使用许可</h2>
            <p>本应用授予您一项有限的、非独占的、不可转让的使用许可，用于个人、非商业用途。您不得在未获我们明确书面同意的情况下对本应用进行复制、修改、分发、出售或出租。</p>

            <h2>2. 用户责任</h2>
            <p>您同意在使用本应用时遵守所有适用的法律法规。您不得通过本应用发布、传输、分发或存储任何违法、有害、侮辱、侵犯他人隐私或其他不适当的内容。</p>

            <h2>3. 知识产权</h2>
            <p>本应用及其所有内容（包括但不限于文本、图像、标志、软件等）的所有权和知识产权归我们或我们的授权方所有。未经我们事先书面同意，您不得复制、模仿或使用本应用的任何部分。</p>

            <h2>4. iOS 内购和订阅</h2>
            <h3>4.1 内购</h3>
            <p>本应用提供了通过Apple的App Store进行的内购选项。这些内购可能包括但不限于一次性购买、消耗性购买、非消耗性购买和自动续订订阅。在您进行购买后，费用将会从您的Apple
                ID账户中扣除。请注意，所有购买均通过Apple处理，我们不直接处理支付事务。</p>

            <h3>4.2 订阅</h3>
            <p>本应用提供自动续订订阅选项。订阅将自动续订，除非您在当前订阅期结束前至少24小时取消。续订费用将在当前订阅期结束前24小时内从您的Apple
                ID账户中扣除，并确定新的订阅期。您可以随时在Apple ID账户设置中管理或取消订阅。</p>

            <h3>4.3 退款政策</h3>
            <p>所有内购和订阅费用均不可退还。对于自动续订订阅，您可以在当前订阅期结束前随时取消续订，但已支付的费用不会退还。如果您认为发生了错误扣款，或对支付有任何疑问，请直接联系Apple支持。</p>

            <h2>5. 终止</h2>
            <p>如果您违反了这些用户条款，我们有权在不通知您的情况下立即终止您对本应用的访问权限。</p>

            <h2>6. 免责声明</h2>
            <p>本应用按“现状”提供，不提供任何明示或暗示的保证。我们不保证本应用的功能不会中断或没有错误，也不对因使用本应用而导致的任何损失或损害承担责任。</p>

            <h2>7. 责任限制</h2>
            <p>在适用法律允许的范围内，对于因使用或无法使用本应用而产生的任何间接、偶然、特殊或后果性损害，我们不承担任何责任。</p>

            <h2>8. 修改条款</h2>
            <p>我们保留随时修改这些用户条款的权利。任何修改将在我们发布更新的用户条款时生效。我们鼓励用户定期查看本条款以了解任何变更。</p>

            <h2>9. 联系我们</h2>
            <p>如果您对这些用户条款有任何疑问或建议，请通过suggestpaqe@163.com联系我们。</p>
        </div>)
};

const TermsOfService = () => {
    const userLanguage = navigator.language || navigator.userLanguage; // Detect browser language
    const language = userLanguage.startsWith('zh') ? 'zh' : 'en'; // Default to 'en' if not Chinese

    return (
        <div style={{margin: 20}}>
            {termsOfService[language]}
        </div>
    );
};

export default TermsOfService;
