import React from 'react';
import './Privacy.css';

// Sample privacy policies
const privacyPolicies = {
    en: (
        <div>
            <h1>Privacy Policy</h1>
            <p>Last Updated: August 14, 2024</p>
            <p>Welcome to EasySchedule (referred to as "we," "our," or "this App"). This Privacy Policy explains how
                we collect, use, and share your information. We value user privacy and are committed to protecting your
                information.</p>

            <h2>1. Information We Collect</h2>
            <p>This App itself does not directly collect or use any personal information. However, we utilize
                third-party service providers that may collect, use, and share user information. By using this App, you
                agree to the privacy policies of these third-party service providers.</p>

            <h3>1.1 AdMob</h3>
            <p>We use Google's AdMob service to display ads. AdMob may automatically collect device information,
                location data, and advertising identifiers (such as Advertising ID). AdMob's usage is governed by
                Google's Privacy Policy. For more information, please refer to <a
                    href="https://policies.google.com/privacy">Google Privacy Policy</a>.</p>

            <h3>1.2 RevenueCat</h3>
            <p>We use RevenueCat to handle in-app purchases. RevenueCat may collect your purchase history, device
                information, and subscription status. RevenueCat's usage is governed by its Privacy Policy. For more
                information, please refer to <a href="https://www.revenuecat.com/privacy">RevenueCat Privacy Policy</a>.
            </p>

            <h2>2. GDPR Compliance</h2>
            <p>If you are an EU user, you have the following rights under the General Data Protection Regulation
                (GDPR):</p>
            <ul>
                <li><strong>Right of Access</strong>: You have the right to know if we hold any of your personal data
                    and to request access to it.
                </li>
                <li><strong>Right to Rectification</strong>: If your data is inaccurate or incomplete, you have the
                    right to request correction.
                </li>
                <li><strong>Right to Erasure</strong>: In certain circumstances, you have the right to request the
                    deletion of your personal data.
                </li>
                <li><strong>Right to Restrict Processing</strong>: In certain circumstances, you have the right to
                    request that we limit the processing of your data.
                </li>
                <li><strong>Right to Data Portability</strong>: You have the right to request that your data be provided
                    to you or another controller in a structured, commonly used, and machine-readable format.
                </li>
            </ul>
            <p>If you wish to exercise these rights or have any privacy-related inquiries, please contact us at
                suggestpaqe@163.com.</p>

            <h2>3. iOS In-App Purchases</h2>
            <p>This App uses iOS in-app purchase features to offer paid content or services. During the purchase
                process, Apple may collect and use your information. For more details on how Apple handles your data,
                please refer to <a href="https://www.apple.com/legal/privacy/">Apple Privacy Policy</a>.</p>

            <h2>4. Security</h2>
            <p>We use reasonable technical and organizational measures to protect user information from unauthorized
                access, use, or disclosure. However, please understand that no method of internet transmission or
                storage is 100% secure.</p>

            <h2>5. Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. Any changes will become effective when we post the
                updated Privacy Policy. We encourage users to periodically review this policy to stay informed of any
                changes.</p>

            <h2>6. Contact Us</h2>
            <p>If you have any questions or suggestions regarding this Privacy Policy, please contact us at
                suggestpaqe@163.com.</p>
        </div>
    ),
    zh: (
        <div>
            <h1>隐私政策</h1>
            <p>更新日期：2024年8月14日</p>
            <p>欢迎使用易排班（以下简称“我们”或“本应用”）。本隐私政策解释了我们如何收集、使用和共享您的信息。本应用重视用户隐私，并致力于保护用户的信息安全。</p>

            <h2>1. 我们收集的信息</h2>
            <p>本应用本身不直接收集或使用用户的任何个人信息。然而，我们使用了第三方服务提供商，这些服务提供商可能会收集、使用和共享用户的信息。使用本应用，即表示您同意第三方服务提供商的隐私政策。</p>

            <h3>1.1 AdMob</h3>
            <p>我们使用了Google的AdMob服务来展示广告。AdMob可能会自动收集设备信息、位置数据以及广告标识符（如广告ID）等信息。AdMob的使用受Google隐私政策的约束。更多信息请参阅<a
                href="https://policies.google.com/privacy">Google隐私政策</a>。</p>

            <h3>1.2 RevenueCat</h3>
            <p>我们使用了RevenueCat来处理应用内购相关的服务。RevenueCat可能会收集您的购买历史记录、设备信息和订阅状态。RevenueCat的使用受其隐私政策的约束。更多信息请参阅<a
                href="https://www.revenuecat.com/privacy">RevenueCat隐私政策</a>。</p>

            <h2>2. GDPR 合规</h2>
            <p>如果您是欧盟用户，根据《通用数据保护条例》（GDPR），您拥有以下权利：</p>
            <ul>
                <li><strong>访问权</strong>：您有权了解我们是否持有您的任何个人数据，并要求访问这些数据。</li>
                <li><strong>更正权</strong>：如果您的数据不准确或不完整，您有权要求更正这些数据。</li>
                <li><strong>删除权</strong>：在某些情况下，您有权要求我们删除您的个人数据。</li>
                <li><strong>限制处理权</strong>：在某些情况下，您有权要求我们限制处理您的数据。</li>
                <li><strong>数据可携权</strong>：您有权要求将您的数据以结构化、常用且机器可读的格式提供给您或其他数据控制者。
                </li>
            </ul>
            <p>如果您希望行使这些权利或有任何与隐私相关的疑问，请通过[您的联系邮箱]联系我们。</p>

            <h2>3. iOS 内购</h2>
            <p>本应用使用iOS的内购功能来提供付费内容或服务。在购买过程中，Apple可能会收集和使用您的信息。更多关于Apple如何处理您的数据的信息，请参阅<a
                href="https://www.apple.com/legal/privacy/">Apple隐私政策</a>。</p>

            <h2>4. 安全性</h2>
            <p>我们使用合理的技术和组织措施来保护用户信息免受未经授权的访问、使用或披露。然而，请理解，尽管我们尽力保护用户的信息，但没有任何互联网传输或存储方法是百分之百安全的。</p>

            <h2>5. 隐私政策的变更</h2>
            <p>我们可能会不时更新本隐私政策。任何更改将在我们发布更新的隐私政策时生效。我们鼓励用户定期查看本隐私政策以了解任何变更。</p>

            <h2>6. 联系我们</h2>
            <p>如果您对本隐私政策有任何疑问或建议，请通过suggestpaqe@163.com联系我们。</p>
        </div>
    )
};

const PrivacyPolicy = () => {
    const userLanguage = navigator.language || navigator.userLanguage; // Detect browser language
    const language = userLanguage.startsWith('zh') ? 'zh' : 'en'; // Default to 'en' if not Chinese

    return (
        <div style={{margin: 20}}>
            {privacyPolicies[language]}
        </div>
    );
};

export default PrivacyPolicy;